const overrides = [
  ['s240egx', 'S240, 42-in. Deck'],
  ['s240ggx', 'S240, 48-in. Deck'],
  ['x330am', 'X330, 42-in. Deck'],
  ['x330bm', 'X330, 48-in. Deck'],
  ['x350am', 'X350, 42-in. Deck'],
  ['x350bm', 'X350, 48-in. Deck'],
  ['x380am', 'X380, 48-in. Deck'],
  ['x380bm', 'X380, 54-in. Deck'],
  ['x390am', 'X390, 48-in. Deck'],
  ['x390bm', 'X390, 54-in. Deck'],
  ['x570am', 'X570, 48-in. Deck'],
  ['x570bm', 'X570, 54-in. Deck'],
  ['x584am', 'X584, 48-in. Deck'],
  ['x584am_54', 'X584, 54-in. Deck'],
  ['x590fm', 'X590, 48-in. Deck'],
  ['x590em', 'X590, 54-in. Deck'],
  ['515ebgx', 'Z515E, 48-in. Deck'],
  ['515ebgx_54', 'Z515E, 54-in. Deck'],
  ['515ebgx_60', 'Z515E, 60-in. Deck'],
  ['530mbgx', 'Z530M, 48-in. Deck'],
  ['530mbgx_54', 'Z530M, 54-in. Deck'],
  ['530mbgx_60', 'Z530M, 60-in. Deck'],
  ['530rfgx', 'Z530R, 54-in. Deck'],
  ['530rfgx_60', 'Z530R, 60-in. Deck'],
  ['545rbgx', 'Z545R, 48-in. Deck'],
  ['545rbgx_54', 'Z545R, 54-in. Deck'],
  ['545rbgx_60', 'Z545R, 60-in. Deck'],
]

/**
 * Format a product title
 * @param {string} sku - SKU of product
 * @param {string} fallback - Fallback title to use if no override is found
 * @returns {string} Returns formatted title or fallback value if no override is found
 */
const formatProductTitle = (sku, fallback) => {
  if (sku) {
    const match = overrides.find(arr => arr[0] === sku)
    if (match) {
      return match[1]
    }
  }
  return fallback
}

module.exports = formatProductTitle
