import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const BulletContainer = props => {
  return <StyledBulletContainer role='navigation' aria-label='Bullet navigation' {...props} />
}

BulletContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledBulletContainer = styled.nav`
  display: inline-block;
  margin-right: 8px;
`

export default BulletContainer
