import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import formatProductTitle from 'utils/format-product-title'
import { itemType } from './series-table-types'

const SeriesTable = ({ items, ...props }) => {
  return (
    <TableContainer {...props}>
      <table>
        <thead>
          <tr>
            <td>Model</td>
            {items[0].tableData.map((spec, key) => (
              <td key={key}>{spec.property || spec.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((prod, key) => (
            <tr key={key}>
              <th>
                <Link to={prod.slug}>{formatProductTitle(prod.sku, prod.name)}&nbsp;›</Link>
              </th>
              {prod.tableData &&
                prod.tableData.map((spec, key) => {
                  return (
                    <td key={key}>
                      <div className='inline-header'>{spec.property || spec.name}</div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: spec.info || spec.data || '<p>-</p>',
                        }}
                      />
                    </td>
                  )
                })}
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  )
}

SeriesTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(itemType)).isRequired,
}

const TableContainer = styled.div`
  background: #fff;
  border: none;
  margin: 0 auto;
  overflow-x: auto;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;

  p {
    line-height: 1.35;
  }

  table {
    border-collapse: collapse;
    width: 100%;

    thead td {
      display: none;
    }

    tbody tr {
      border: 1px solid #d7d7d7;
      margin-bottom: 25px;
      font-size: 0.9em;
      display: block;

      :last-of-type {
        margin-bottom: 15px;
      }
    }

    tbody th {
      font-weight: bold;
      padding: 15px;
      background-color: #000;
      color: #fff;
      display: block;
      width: 100%;

      a {
        color: #fff;
      }
    }

    tbody td {
      padding: 10px 15px;
      display: block;
      width: 100%;

      div {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
        vertical-align: middle;
      }

      .inline-header {
        display: inline-block;
        font-weight: bold;
      }

      :nth-child(even) {
        background-color: #f7f7f7;
      }

      p {
        margin: 0;
        padding: 0;
      }

      a {
        text-decoration: none;
      }
    }
  }

  @media (min-width: 900px) {
    border: 1px solid #d7d7d7;

    table {
      thead td {
        background-color: #000;
        color: #fff;
        padding: 20px 16px;
        font-weight: bold;
        font-size: 0.9em;
        display: table-cell;

        :first-of-type {
          padding-left: 25px;
        }

        :last-of-type {
          padding-right: 25px;
        }
      }

      tbody tr {
        border: none;
        font-size: 0.9em;
        display: table-row;

        :not(:last-of-type) {
          border-bottom: 1px solid #d7d7d7;
        }

        :nth-child(even) {
          background-color: #f7f7f7;

          th {
            background-color: #f7f7f7;
          }
        }

        :nth-child(odd) {
          background-color: #fff;

          th {
            background-color: #fff;
          }
        }
      }

      tbody th {
        font-weight: bold;
        padding: 20px 16px;
        color: #377539;
        display: table-cell;
        width: auto;

        a {
          color: #377539;

          :hover,
          :focus {
            color: #333;
            text-decoration: underline;
          }
        }
      }

      tbody td {
        display: table-cell;
        width: auto;
        padding: 20px 16px;

        div {
          display: block;
          width: 100%;
        }

        .inline-header {
          display: none;
        }

        :nth-child(even) {
          background-color: inherit;
        }

        :last-of-type {
          padding-right: 25px;
        }
      }
    }
  }
`

export default SeriesTable
