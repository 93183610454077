import * as PropTypes from 'prop-types'

export const itemType = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string,
  slug: PropTypes.string.isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      // property or name
      property: PropTypes.string,
      name: PropTypes.string,
      // info or data
      info: PropTypes.string,
      data: PropTypes.string,
    })
  ).isRequired,
}
