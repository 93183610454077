import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import UnstyledButton from 'components/button/unstyled-button'
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight'
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft'

const NavArrow = ({ direction, disabled, kind, ...props }) => {
  const Arrow = direction === 'left' ? FaChevronLeft : FaChevronRight
  return (
    <StyledNavArrow
      disabled={disabled}
      data-theme={kind}
      aria-label={direction === 'left' ? 'Previous' : 'Next'}
      {...props}
    >
      <Arrow aria-hidden='true' focusable='false' role='presentation' />
    </StyledNavArrow>
  )
}

NavArrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  disabled: PropTypes.bool.isRequired,
  kind: PropTypes.oneOf(['dark', 'light']).isRequired,
}

NavArrow.defaultProps = {
  disabled: false,
  kind: 'light',
}

const StyledNavArrow = styled(UnstyledButton)`
  color: ${props => {
    switch (props['data-theme']) {
      case 'dark':
        return 'rgba(255, 255, 255, 0.5)'
      case 'light':
      default:
        return props.theme.color.n100
    }
  }};
  font-size: 16px;
  padding: 8px;

  :hover,
  :focus {
    color: ${props => {
      switch (props['data-theme']) {
        case 'dark':
          return 'rgba(255, 255, 255, 0.75)'
        case 'light':
        default:
          return props.theme.color.n600
      }
    }};
  }

  &[disabled] {
    color: ${props => {
      switch (props['data-theme']) {
        case 'dark':
          return 'rgba(255, 255, 255, 0.25)'
        case 'light':
        default:
          return props.theme.color.n50
      }
    }};
    cursor: disabled;
    pointer-events: none;
  }
`

export default NavArrow
