import React from 'react'
import { Link } from 'gatsby'

const deereData = [
  {
    series: '100-series',
    description: (
      <p>
        If you’re looking for a comfortable and easy-to-use lawn mower at an affordable price, the
        John Deere 100 Series lawn tractors are for you. Built with comfort and quality in mind,
        you’ll find features that other cheap lawn mowers can’t provide. Key features include a
        wide, comfortable operating station, adjustable seat, simple control panel, redesigned mower
        deck lift lever, side-by-side pedals, and even cruise control (all models except the S100,
        S110, and S140). Even the oil change has been made easier with John Deere’s one of a kind
        Easy Change™ 30-Second Oil Change System, available on all models except the S100, S110, and
        S140.
      </p>
    ),
    metaDescription:
      'Comfortable and easy-to-use lawn mowers at an affordable price. The John Deere 100 Series was built with quality and comfort in mind.',
    videoId: 'M9EH3HGWyMk',
    videoList: 'PLUz5fj7f_mw8dQtkED4pkdqJQwINvbH0f',
  },
  {
    series: '200-series',
    description: (
      <p>
        Mow better, faster with the John Deere 200 Series lawn mower. You’ll find the same features
        as the <Link to='/lawn-garden/lawn-tractors/100-series/'>100 Series</Link> (
        <Link to='/knowledge-center/twin-touch-foot-controls/'>Twin Touch Pedals</Link>, wide
        operator station, cruise control, and spring assisted mower deck lift lever) plus more! The
        200 Series comes with an upgraded 15-in. open, high-back seat with 10 adjustable positions.
        You’ll also find a 42 or 48-in{' '}
        <Link to='/blog/accel-deep-deck/'>Accel Deep mower deck</Link> compatible with a{' '}
        <Link to='/knowledge-center/mulchcontrol-kit/'>MulchControl Kit</Link>, delivering an
        affordable <Link to='/blog/mulching-vs-bagging/'>mulching lawn mower</Link>.
      </p>
    ),
    metaDescription:
      'Mow better, faster with the John Deere 200 Series lawn mower, featuring an upgraded 15-in. open, high-back seat and MulchControl Kit compatible Accel Deep mower deck.',
    videoId: 'UJe8wFozowc',
    videoList: 'PLUz5fj7f_mw9SWG5e4IfB4_kvjvu54M4t',
  },
  {
    series: 'x300-series',
    description: (
      <p>
        Get things done around your yard, no matter what season it is, with John Deere X300 Select
        Series lawn mowers. With the ability to mount attachments on the front and back, you’ll be
        using your lawn tractor for more than just mowing. Select models feature 4-wheel steering
        and come in 3 <Link to='/blog/accel-deep-deck/'>Accel Deep mower deck</Link> sizes: 42-in,
        48-in, and 54-in. The X300 Series also features optional{' '}
        <Link to='/knowledge-center/mulchcontrol-kit/'>
          electric One-Touch MulchControl technology
        </Link>
        , which allows you to go from <Link to='/blog/mulching-vs-bagging/'>mulching</Link> to
        discharging with the flip of a switch, and{' '}
        <Link to='/knowledge-center/twin-touch-foot-controls/'>Twin Touch foot controls</Link> for
        better control.
      </p>
    ),
    metaDescription:
      'Get things done around your yard, no matter what season it is, with John Deere X300 Select Series lawn mowers with exclusive One-Touch MulchControl.',
    videoId: 'ITNFV5f0EwE',
    videoList: 'PLUz5fj7f_mw9s1ItEVDWX-C9R4DPmK0g8',
  },
  {
    series: 'x500-series',
    description: (
      <p>
        Power through your yard with tough John Deere X500 Select Series lawn mowers. Traction
        assist and heavy-duty integrated transmissions combined with a larger frame make a lawn
        tractor that can’t be stopped by steep inclines and muddy ground conditions. These mowers
        also include{' '}
        <Link to='/knowledge-center/twin-touch-foot-controls/'>Twin Touch foot controls</Link>,
        48-in or 54-in <Link to='/blog/accel-deep-deck/'>Accel Deep mower decks</Link>, and optional{' '}
        <Link to='/knowledge-center/mulchcontrol-kit/'>MulchControl</Link> (one-touch or
        lever-action) for added control.
      </p>
    ),
    metaDescription:
      'Tough conditions have met their match: John Deere X500 Select Series lawn mowers with larger frame, traction assist, and heavy-duty integrated transmissions.',
    videoId: 'ITNFV5f0EwE',
    videoList: 'PLUz5fj7f_mw8orM-Su671sxwPgeRJ_l4J',
  },
  {
    series: 'x700-series',
    description: (
      <p>
        Get the ultimate lawn tractor experience with the John Deere X700 Signature Series. These
        high-end models are packed with tons of features to help you do more. Choose between a
        powerful EFI or diesel engine and add a 48-in{' '}
        <Link to='/blog/accel-deep-deck/'>Accel Deep mower deck</Link> or larger 54-in or 60-in High
        Capacity drive-over mower deck with optional{' '}
        <Link to='/knowledge-center/mulchcontrol-kit/'>MulchControl</Link>. More than just a lawn
        mower, the X700 Series tractors come with a 3-point hitch and 540 RPM PTO to power
        heavy-duty attachments. These powerful lawn tractors also come in 4-wheel steering models
        and include{' '}
        <Link to='/knowledge-center/twin-touch-foot-controls/'>Twin Touch foot controls</Link>,
        power steering, and hydraulic lift.
      </p>
    ),
    metaDescription:
      'Get the ultimate lawn tractor experience with the John Deere X700 Signature Series, packed with tons of features to help you do more. ',
    videoId: 'dpANwb8tKLM',
    videoList: 'PLUz5fj7f_mw_PgvVKvv5Z5Za7UJ9fHMea',
  },
  {
    series: 'z300-series',
    description: (
      <p>
        Take on your lawn with a John Deere Z300 Series zero-turn mower. Hutson has the full line-up
        of John Deere residential zero-turns, including seven Z300 models with several options to
        choose from. Start with a 42-in, 48-in, or 54-in{' '}
        <Link to='/blog/accel-deep-deck/'>Accel Deep mower deck</Link> and a comfortable 18-in or
        20-in seat. These small zero-turn mowers have a low profile making it easier to cut under
        trees. They also have easy-to-access oil filter, tube, and drain, a removable footrest
        platform, and a service interval decal for convenience. If you’re looking for a great
        all-around zero-turn lawn mower, look no further than the Z300 Series.
      </p>
    ),
    metaDescription:
      'Looking for a great all-around zero-turn mower? Check out a John Deere Z300 Series ZTrak with a low profile, tough Accel Deep deck, and excellent serviceability.',
    videoId: '',
    videoList: '',
  },
  {
    series: 'z500-series',
    description: (
      <p>
        Mow better, faster on a Z500 Series John Deere zero-turn mower. Hutson carries all 5 John
        Deere Z500 residential zero-turns with options for everyone. Under these mowers you’ll find
        advanced <Link to='/blog/mulching-vs-bagging/'>mulch-ready</Link> mower decks ranging from
        48-in to 62-in. Get convenient serviceability with the easy to access fuel and oil filters,
        oil drain, and dip stick, removable footrest platform, and detailed service interval decal.
        Last but not least, you’ll have a smoother, more comfortable ride with larger caster wheels,
        comfortable 15-in, 18-in or 21-in seat with suspension, and 18 possible control positions.
        With a zero-turn mower this comfortable, you’ll be looking for excuses not to mow your lawn.
      </p>
    ),
    metaDescription:
      'For a cleaner and quicker cut, check out the John Deere Z500 Series zero-turn mowers. One of the most comfortable and easy-to-use zero-turns you’ll find.',
    videoId: 'mK7m73iWk9M',
    videoList: 'PLUz5fj7f_mw8c62TunMJfecoBOMgduDKM',
  },
  {
    series: 'z700-series',
    description: (
      <p>
        Want the commercial mower experience but don’t feel like paying for it? The Z700 Series John
        Deere zero-turn mowers were made for you. They’re the ideal size for large property owners
        or anyone with a few acres to mow. These zero-turn mowers come with 9-gauge steel
        High-Capacity PRO mower decks with commercial-grade reinforcements in 48-in, 54-in, or 60-in
        sizes. Get a more comfortable ride with adjustable steering levers, 3-position foot
        platform, and{' '}
        <Link to='/knowledge-center/comfortglide-suspension/'>ComfortGlide Suspension seat</Link> (M
        and R models only). Run with optional{' '}
        <Link to='/knowledge-center/tweels/'>Michelin Tweels</Link> and an 8-gallon fuel tank
        (nearly double the capacity of a{' '}
        <Link to='/lawn-garden/residential-zero-turns/z500-series/'>Z500</Link>) and nothing can
        stop you. The convenient integrated park brake will let you know when the brake is engaged
        or disengaged so you can focus on the task at hand. To get all the great features and more,
        get a John Deere Z700 Series zero-turn mower.
      </p>
    ),
    metaDescription:
      'For a residential zero-turn mower with commercial-grade features, check out the John Deere Z700 Series. Unmatched quality and performance plus a comfortable ride.',
  },
  {
    series: 'z900-series',
    metaDescription:
      'For a commercial lawn mower that works for you, trust the John Deere ZTrak commercial zero-turn mowers. Hutson has the full line-up, including gas and diesel options.',
    description: (
      <p>
        Whether you’re looking for commercial-grade features on a budget or the power of a diesel
        zero-turn mower, the Z900 John Deere Series commercial mowers have it all. The Z900 line-up
        includes over 10 models to choose from with a wide range of options. Underneath you’ll find
        thick 7-gauge steel 7-Iron decks with optional Mulch On Demand in 48-in to 72-in sizes.
        Deluxe seats with optional{' '}
        <Link to='/knowledge-center/comfortglide-suspension/'>ComfortGlide Suspension</Link> offer
        best-in-class ride and comfort. John Deere zero-turn commercial mowers come with 4 fuel
        options: gas, EFI, propane, and diesel. Optional{' '}
        <Link to='/knowledge-center/tweels/'>Michelin Tweels</Link> paired with the large
        11.5-gallon tank will keep you running day-in, day-out. If you're not sure where to start,
        check out our{' '}
        <Link to='/blog/john-deere-commercial-zero-turn-trim-levels/'>
          article on the difference between E, M, and R series John Deere commercial zero-turn
          mowers
        </Link>
        .
      </p>
    ),
    videoId: 'q0nT_uNTPvM',
    videoList: 'PLUz5fj7f_mw8InyWyVxbXnqij_JM6Yoi9',
  },
  {
    series: 'quiktrak-series',
    description: (
      <p>
        For a commercial stand-on mower you can stand behind, check out the John Deere QuikTrak
        stand-on lawn mowers. The QuikTrak line-up features 9 models with 7-gauge steel decks
        ranging from 36 inches to 61 inches. Mower decks come with optional mulching kit and feature
        a floating deck design with lift lever that allows for height adjustments from the operator
        station. Along with the lift lever, the controls have been redesigned for more comfort and
        convenience. A compact design makes for easier hauling and superior maneuverability you’d
        expect from a John Deere stand-on mower. With optional{' '}
        <Link to='/knowledge-center/tweels/'>Michelin Tweels</Link>, you can get to those hard to
        reach places without risking a flat tire. For a commercial lawn mower that stands apart,
        lean on a John Deere QuikTrak from Hutson.
      </p>
    ),
    metaDescription:
      'Convenient and compact John Deere QuikTrak stand-on mowers were designed for improved maneuverability and productivity. Get a stand-on you can stand behind at Hutson.',
    videoId: '6bSkaklUjyg',
    videoList: 'PLUz5fj7f_mw_rXRapXJ-r6-gE1NZ7V3Rw',
  },
  {
    series: 'walk-behind-mowers',
    description: (
      <p>
        The new John Deere walk-behind mowers were engineered with the operator in mind. Their
        controls are conveniently located and feature an ergonomic design that’s comfortable, even
        after extended use. A compact design allows you to mow hard to reach places and provides
        easier hauling and maneuverability. The 2019 R series John Deere commercial walk-behind
        mowers have 8.5” of frame clearance and a rear wheel for additional anti-scalp and curb
        climbing protection. Deck sizes on all walk-behind lawn mowers range from 31-in to 61-in and
        can have an optional mulching kit installed. The 2019 models also feature a hydrostatic
        drive system for additional power and a smoother operation.
      </p>
    ),
    metaDescription:
      'Engineered with the operator in mind, the new John Deere walk-behind mowers feature ergonomic controls, a compact design, and improved scalp and curb protection.',
    videoId: '85E5oDD652I',
    videoList: 'PLUz5fj7f_mw-Rhtbe2jPg5pPWZxBWyYX9',
  },
  {
    series: '1-family',
    metaDescription:
      'Compact but powerful, the 23 and 25 HP John Deere 1 Series tractors are ideal for small properties or farms and can double as a riding mower with the AutoConnect deck.',
    description: (
      <p>
        John Deere 1 Series tractors are compact but mighty with 23 and 25 HP options. These mini
        tractors are capable of equipping loaders, backhoes, blades, mid-mount mowers, and much
        more. The AutoConnect Drive Over mower deck gives these compact utility tractors the
        functionality of a riding lawn mower. The John Deere 1 Family redefines its class with
        unmatched stability, ease of use, ride comfort, and attachability, making excellent small
        property or small farm tractors.
      </p>
    ),
    videoId: 'ZzFyJ-lb1Bo',
    videoList: 'PLUz5fj7f_mw-YAfrwn_1N3aAkjV5eDOYs',
  },
  {
    series: '2-family',
    metaDescription:
      'Redesigned from the ground up, the new John Deere 2 Series compact utility tractors come in 25, 32, and 38 HP and are ideal for mowing, loading, tilling, and removing snow.',
    description: (
      <p>
        John Deere 2 Series tractors were redesigned from the ground up to make work easier. With
        options ranging from 25 to 38 HP, these mini tractors pack a punch. They're capable of
        attaching loaders, blades, backhoes, mid-mount mowers, and more, making them an excellent
        choice for use on small properties or farms. John Deere 2 Family compact utility tractors
        are heavier and sturdier than the{' '}
        <Link to='/lawn-garden/compact-tractors/1-family/'>1 Series</Link> but are just as easy and
        comfortable to use.
      </p>
    ),
    videoId: 'Adf4KKr-qQc',
    videoList: 'PLUz5fj7f_mw_EOh_m927xk5P9LUmPKNF_',
  },
  {
    series: '3-family',
    metaDescription: '',
    videoId: 'D7-loBhFMd4',
    videoList: 'PLUz5fj7f_mw-tAyQOx5PaorChaBnEVtVv',
  },
  {
    series: '4-family',
    metaDescription: '',
    videoId: 'gFKyOWKRuTU',
    videoList: 'PLUz5fj7f_mw_tDY8I_zTdeC4p9v-iPLdJ',
  },
  {
    series: '5-family',
    metaDescription: '',
    videoId: 'R6ui0TBt3YE',
    videoList: 'PLUz5fj7f_mw_aWj19WY7AQ5_rZM2eL5GM',
  },
  {
    series: '6-family-utility',
    metaDescription: '',
    videoId: 'EWbV39DxkdU',
    videoList: 'PLUz5fj7f_mw9uiiExJskM1krfj6NigD-8',
  },
  {
    series: 'work-series-gators',
    metaDescription: '',
    videoId: 'q9XeTe9UVdk',
    videoList: 'PLUz5fj7f_mw-8hPMrVJJy6xqU0esjep-A',
  },
  {
    series: 'full-size-crossover-gators',
    metaDescription: '',
    videoId: 'q9XeTe9UVdk',
    videoList: 'PLUz5fj7f_mw9I-t_9qvw5tOy8LuWlqDA8',
  },
  {
    series: 'mid-size-crossover-gators',
    metaDescription: '',
    videoId: 'q9XeTe9UVdk',
    videoList: 'PLUz5fj7f_mw9DBWaiSdz8jgwWWvNaRYLf',
  },
  {
    series: 'skid-steers',
    metaDescription: '',
    videoId: '0jU5KrR9tb4',
    videoList: 'PLUz5fj7f_mw-1QgtpOl5c35w84S2rpJLP',
  },
  {
    series: 'compact-track-loaders',
    metaDescription: '',
    videoId: 'C7VffaNxHpA',
    videoList: 'PLUz5fj7f_mw91fyFTo5_GNdzmaW4IW0tP',
  },
  {
    series: 'compact-excavators',
    metaDescription: '',
    videoId: 'zQZgftUEvvw',
    videoList: 'PLUz5fj7f_mw-JxoKOlc6DTTEYHj3p-XuD',
  },
  {
    series: 'compact-wheel-loaders',
    metaDescription: '',
    videoId: 'gnGz383GmrY',
    videoList: 'PLUz5fj7f_mw_29icK-VFIXODLEgBvhJ_U',
  },
  {
    series: 's-series',
    metaDescription:
      'Learn more about the John Deere S-Series Combines at Hutson that is changing the game for farmers.',
    videoId: 'QO8qTLf48Do',
    videoList: 'PLUz5fj7f_mw-8_b1GB6UwhQPCp-C0VtcR',
  },
  {
    series: '0-series-balers',
    metaDescription:
      'Check out the economic and productive John Deere 0-Series balers, visit your local Hutson store for the best service and pricing around.',
  },
  {
    series: 'wrapping-balers',
    metaDescription: '',
    videoId: 'bRO-xs-OVOk',
    videoList: 'PLUz5fj7f_mw_GjRIcf4IEmNbAxkkJWXpy',
  },
  {
    series: 'disc-mowers',
    metaDescription:
      'Get a John Deere and get the best Disc Mower money can buy. Hutson carries a full line of Disc Mowers to suit all of your hay cutting needs.',
    videoId: 'VzgCFNXD6cA',
    videoList: 'PLUz5fj7f_mw92iNPFIx03cdCWK8kuE_Gg',
  },
  {
    series: 'mower-conditioners',
    metaDescription:
      'Produce some of the best hay with the John Deere MoCo at Hutson Inc, your local John Deere dealer. Extra durable to handle those tough conditions that you throw at it during hay season.',
    videoId: '_a3Cqqk93o0',
    videoList: 'PLUz5fj7f_mw-X_bri5uFKrQ_5-4b3fNcB',
  },
  {
    series: 'small-square-balers',
    metaDescription:
      'Get the perfect square bales every time with a brand new John Deere square baler at Hutson Inc. Producing consistent bales, no matter what you are baling.',
  },
  {
    series: 'air-drills',
    metaDescription:
      'Learn more about the features and advantages of John Deere air seeders at Hutson Inc. See what all models we offer and what they can do for your operation.',
    videoId: 'JbXMQ1M7BBg',
    videoList: 'PLUz5fj7f_mw9ltXM3eOWpQR8o6cPlzrSP',
  },
  {
    series: 'box-drills',
    metaDescription:
      'Seeding technology has come a long way. Compare the different John Deere box drills that we carry here at Hutson Inc.',
    videoId: '1rxlpZKEujM',
    videoList: 'PLUz5fj7f_mw_mpuVjm9av1Hcp72kogMCz',
  },
  {
    series: 'db-planters',
    metaDescription:
      'Visit a Hutson location near you to see a full line of John Deere DB Planters and learn how they can help maximize your yield potential.',
  },
  {
    series: 'drawn-planters',
    metaDescription:
      'John Deere drawn planters are the best in the industry. Come and see out our full line of John Deere planters at Hutson Inc.',
    videoId: '7d7HQeAax_s',
    videoList: 'PLUz5fj7f_mw9OOTeh5_mSzycEPzs3uFlR',
  },
  {
    series: '6-family-row',
    metaDescription:
      'Check out the most versatile tractors from the John Deere 6-series at Hutson Inc, your local John Deere dealer.',
    videoId: 'EWbV39DxkdU',
    videoList: 'PLUz5fj7f_mw9uiiExJskM1krfj6NigD-8',
  },
  {
    series: '7-family',
    metaDescription:
      'The 7-series John Deere row crop tractor is powerful yet versatile. Check out our full line of 7R tractors at Hutson Inc.',
  },
  {
    series: '8-family',
    metaDescription:
      "The John Deere 8R/8RT Row-Crop Tractor from John Deere ranges from 245 to 370 engine horsepower and offers a ton of power. Check out Hutson Inc's inventory and amazing deals.",
    videoId: 'CnOhj7a1B98',
    videoList: 'PLUz5fj7f_mw9U7_2eB4_0EGclJf87uSFA',
  },
  {
    series: '9-family',
    metaDescription:
      "The new 9R Series John Deere tractors are built to defy conventions. With more power, and more luxury, you won't be able to look away. Check out these tractors at Hutson, your local John Deere dealer.",
    videoId: '6WpwBAl0I-4',
    videoList: 'PLUz5fj7f_mw_lmABrT1FEUjzpmssKy-Dv',
  },
  {
    series: 'hagie-sprayers',
    metaDescription:
      'Hagie sprayers offer full-season capabilities that allow you to tend to your crop at all stages. Hutson Inc is a dealer of Hagie Equipment and offers full-service capabilities.',
    videoId: 'YxHBELPrzdU',
    videoList: 'PLUz5fj7f_mw-OcM-aBtfw5GUFUt8NoLfm',
  },
  {
    series: 'john-deere-sprayers',
    metaDescription:
      "John Deere sprayers offer full-season capabilities and are equipped with the technology that you've come to expect from John Deere. Stop by Hutson, your local John Deere dealer to learn more.",
    videoId: 'TKzO7u9OLX0',
    videoList: 'PLUz5fj7f_mw_h_DgrfqSR5GjceYjdEc3g',
  },
  {
    series: 'field-cultivators-mulch-finishers',
    metaDescription: '',
  },
  {
    series: 'rippers',
    metaDescription: '',
    videoId: 'rdkKJ-fY1gk',
    videoList: 'PLUz5fj7f_mw_bTGboRVGx8lDQ7tYilMPT',
  },
  {
    series: 'chisel-plows',
    metaDescription:
      'Learn more about the features on the latest John Deere Chisel Plows at Hutson, your local John Deere dealer.',
  },
  {
    series: 'moldboard-plows',
    metaDescription: '',
  },
  {
    series: 'mulch-tillers',
    metaDescription: '',
  },
  {
    series: 'disks',
    metaDescription: '',
    videoId: '-xjrNY7jrdQ',
    videoList: 'PLUz5fj7f_mw9VRz3ROdCB5iuBSr8mWXRS',
  },
  {
    series: 'rotary-cutters',
    metaDescription: '',
  },
]

export default deereData
