import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import P from 'components/new/typography/p'
import H3 from 'components/new/typography/h3'
import H4 from 'components/new/typography/h4'
import formatPrice from 'utils/format-price'
import getUsedEquipmentImageSrc from 'utils/get-used-equipment-image-src'

const UsedEquipmentCard = ({
  primaryImage,
  imageAlt = '',
  title,
  slug,
  stockNumber,
  initialPrice,
  price,
  tabIndex,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={slug} tabIndex={tabIndex}>
        <div className='image-container'>
          <img
            src={getUsedEquipmentImageSrc(primaryImage, { relativeFallback: true })}
            alt={imageAlt}
            loading='lazy'
          />
        </div>
        <div className='text-container'>
          <H4 as='p' className='title'>
            {title}
          </H4>
          {stockNumber ? <P className='stock-number'>Stock #{stockNumber}</P> : null}
          <div>
            <H3 as='p' className='price'>
              {formatPrice(price, { round: true })}
            </H3>
            {price < initialPrice ? (
              <span className='reduced-price'>{formatPrice(initialPrice, { round: true })}</span>
            ) : null}
          </div>
        </div>
      </Link>
    </Container>
  )
}

UsedEquipmentCard.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  initialPrice: PropTypes.number.isRequired,
  stockNumber: PropTypes.number,
  title: PropTypes.string.isRequired,
}

UsedEquipmentCard.defaultProps = {
  imageAlt: '',
}

const Container = styled.div`
  margin: 0 8px;
  width: 100%;

  > a {
    background-color: #fff;
    border: 1px solid ${props => props.theme.color.n30};
    color: #000;
    display: block;
    text-decoration: none;

    :hover,
    :focus {
      .title {
        color: ${props => props.theme.color.g400};
        text-decoration: underline;
      }
    }
  }

  .image-container {
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
    }

    :before {
      display: none;
    }

    :after {
      display: none;
    }

    @media (min-width: 800px) {
      :before {
        content: '';
        display: block;
        float: left;
        height: 0;
        margin-left: -1px;
        padding-bottom: 66.67%;
        width: 1px;
      }

      :after {
        clear: both;
        content: '';
        display: table;
      }

      img {
        position: absolute;
        min-width: 100%;
        max-height: none;
        max-width: none;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .text-container {
    padding: 16px;
  }

  .title {
    font-weight: bold;
    line-height: 1.35;
  }

  .stock-number {
    color: ${props => props.theme.color.n400};
    font-size: 16px;
    margin: 0 0 8px;
  }

  .price {
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    margin-right: 10px;
  }

  .reduced-price {
    color: #7d7d7d;
    display: inline-block;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1;
    text-decoration: line-through;
  }
`

export default UsedEquipmentCard
