import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const PausePlayButtonContainer = props => {
  return <StyledPausePlayButtonContainer {...props} />
}

PausePlayButtonContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledPausePlayButtonContainer = styled.div`
  display: inline-block;
`

export default PausePlayButtonContainer
