const getSeriesSpecsTables = (allProducts, defaultTableTitle = 'Compare specifications') => {
  return allProducts
    .reduce((acc, product) => {
      // Sort items by table
      const item = {
        name: product.name,
        slug: product.slug,
        image: product.image,
        seriesTableData: product.seriesTableData,
      }

      // Use specified table name or series title as a fallback
      const tableName = product.seriesTableName || defaultTableTitle

      const accMatch = acc.find(obj => obj.name === tableName)

      if (accMatch) {
        accMatch.items.push(item)
      } else {
        acc.push({
          name: tableName,
          items: [item],
        })
      }

      return acc
    }, [])
    .map(table => {
      // Ignore empty tables. Some rows will have no data (can't trust data from Deere's website).
      const populatedItems = table.items.filter(item => item.seriesTableData)

      // Remove properties that don't exist in all items
      const propertiesToKeepIfAny = populatedItems
        .reduce((properties, item) => {
          const itemProperties = item.seriesTableData.map(data => {
            return {
              property: data.property,
              // TODO: Consolidate this to one property in graphql. Also update in series table grid component
              keep: Boolean(data.info ?? data.data),
            }
          })

          // Initialize the list
          if (!properties) {
            return itemProperties
          }

          // Remove properties that aren't in the item properties
          return properties
            .map(({ property, keep }) => {
              const itemPropertiesMatch = itemProperties.find(obj => obj.property === property)

              // If this item doesn't have the property, remove the property
              if (!itemPropertiesMatch) {
                return null
              }

              return {
                property,
                // If any of the items have a value for this property, keep it. Otherwise, we'll remove it later.
                keep: keep || itemPropertiesMatch.keep,
              }
            })
            .filter(val => val !== null)
        }, null)
        ?.reduce((properties, item) => {
          // Get list of properties to keep
          if (item.keep) {
            properties.push(item.property)
          }

          return properties
        }, [])

      // We have to leave this here just in case all property values in every item are empty
      const propertiesToKeep = Array.isArray(propertiesToKeepIfAny) ? propertiesToKeepIfAny : []

      // Update items
      const items = table.items.map(item => {
        // Filter out unwanted properties and sort. If table is empty, add blank values for each property.
        const tableData = item.seriesTableData
          ? propertiesToKeep.map(property => {
              const itemPropertyMatch = item.seriesTableData.find(obj => obj.property === property)
              return itemPropertyMatch
            })
          : propertiesToKeep.map(property => {
              return {
                property,
                info: null,
              }
            })

        return {
          ...item,
          tableData,
        }
      })

      return {
        ...table,
        items,
      }
    })
}

module.exports = getSeriesSpecsTables
