import React, { useRef, useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import YouTube from 'react-youtube'
import { useIsMounted } from 'hooks/use-is-mounted'
import isDefined from 'utils/is-defined'
import noop from 'utils/noop'
import useMeasure from 'react-use/lib/useMeasure'
import useMedia from 'react-use/lib/useMedia'
import UnstyledButton from 'components/button/unstyled-button'

const formatVideoDuration = val => {
  const numberArr = val
    .slice(2, val.length - 1)
    .replace(/[a-z]/gi, ':')
    .split(':')

  return numberArr.map(val => (val.length === 1 ? `0${val}` : val)).join(':')
}

const Youtube = ({ onReady, list, title, videoId, ...props }) => {
  const [containerRef, inView] = useInView({ triggerOnce: true })
  const [currentVideo, setCurrentVideo] = useState()
  const playerRef = useRef()
  const [measurementsRef, { height: playerHeight }] = useMeasure()
  const fetchingPlaylist = useRef(false)
  const [playlistInfo, setPlaylistInfo] = useState()
  const [ready, setReady] = useState(false)
  const isMounted = useIsMounted()

  const showing = inView && ready
  const isDesktop = useMedia('(min-width: 900px)')

  const onPlayerReady = async e => {
    if (isMounted()) {
      if (fetchingPlaylist.current || playlistInfo) {
        return
      }

      fetchingPlaylist.current = true

      const playlistSearchParams = new URLSearchParams({
        part: 'snippet',
        id: list,
        key: process.env.GATSBY_GOOGLE_API_KEY,
      })

      const playlist = await fetch(
        `https://youtube.googleapis.com/youtube/v3/playlists?${playlistSearchParams.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then(res => res.json())

      const playlistItemsSearchParams = new URLSearchParams({
        part: ['contentDetails'],
        playlistId: list,
        key: process.env.GATSBY_GOOGLE_API_KEY,
        maxResults: 50,
      })

      const playlistItems = await fetch(
        `https://youtube.googleapis.com/youtube/v3/playlistItems?${playlistItemsSearchParams.toString()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      ).then(res => res.json())

      if (Array.isArray(playlistItems.items) && playlistItems.items.length > 0) {
        const videosSearchParams = new URLSearchParams({
          part: ['snippet', 'contentDetails'],
          id: playlistItems.items.map(item => item.contentDetails.videoId),
          key: process.env.GATSBY_GOOGLE_API_KEY,
          maxResults: 50,
        })

        const videos = await fetch(
          `https://youtube.googleapis.com/youtube/v3/videos?${videosSearchParams.toString()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        ).then(res => res.json())

        const newPlaylistInfo = {}

        if (Array.isArray(playlist.items) && playlist.items.length >= 1) {
          newPlaylistInfo.title = playlist.items[0].snippet.title
        }

        if (Array.isArray(videos.items)) {
          newPlaylistInfo.items = videos.items.reduce((acc, video) => {
            const id = video.id
            const matchIndex = acc.findIndex(obj => obj.id === id)
            if (matchIndex === -1) {
              acc.push({
                channelId: video.snippet.channelId,
                channelTitle: video.snippet.channelTitle,
                duration: video.contentDetails.duration,
                id,
                publishedAt: video.snippet.publishedAt,
                thumbnails: video.snippet.thumbnails,
                title: video.snippet.title,
              })
            }
            return acc
          }, [])

          const currentVideoId =
            isDefined(videoId) && videos.items.findIndex(obj => obj.id === videoId) !== -1
              ? videoId
              : videos.items[0].id

          e.target.cueVideoById(currentVideoId)
          setCurrentVideo(currentVideoId)
        }

        setPlaylistInfo(newPlaylistInfo)
      }

      fetchingPlaylist.current = false

      setReady(true)
      onReady()
    }
  }

  const changeVideo = id => {
    if (id === currentVideo) {
      return
    }
    const internalPlayer = playerRef.current.getInternalPlayer()
    if (internalPlayer) {
      internalPlayer.loadVideoById(id)
      setCurrentVideo(id)
    }
  }
  return (
    <YoutubePlaylistContainer>
      <YoutubeContainer
        ref={containerRef}
        tabIndex={!showing ? '0' : null}
        className={showing ? 'youtube-container-showing' : ''}
        aria-busy={!showing}
        aria-live='polite'
        aria-label={!showing ? 'Loading video' : null}
      >
        {inView && <YouTube {...props} onReady={onPlayerReady} videoId={videoId} ref={playerRef} />}
        <MeasurementsKeeper ref={measurementsRef} />
      </YoutubeContainer>
      <YoutubeSidebar style={{ height: isDesktop && playerHeight !== 0 ? playerHeight : 'auto' }}>
        <h2>
          {title
            ? title
            : isDefined(playlistInfo) && isDefined(playlistInfo.title)
            ? playlistInfo.title
            : 'Playlist'}
        </h2>
        <ol>
          {isDefined(playlistInfo) && Array.isArray(playlistInfo.items)
            ? playlistInfo.items.map((video, index) => (
                <li key={video.id}>
                  <UnstyledButton
                    className={currentVideo === video.id ? 'current' : null}
                    onClick={() => changeVideo(video.id)}
                  >
                    <div className='video-index'>{index + 1}.</div>
                    <div className='video-details'>
                      <div className='video-title'>{video.title}</div>
                      <div className='video-info'>
                        {video.channelTitle} • {formatVideoDuration(video.duration)}
                      </div>
                    </div>
                  </UnstyledButton>
                </li>
              ))
            : null}
        </ol>
      </YoutubeSidebar>
    </YoutubePlaylistContainer>
  )
}

Youtube.propTypes = {
  onReady: PropTypes.func,
  list: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string,
}

Youtube.defaultProps = {
  onReady: noop,
}

const MeasurementsKeeper = styled.div`
  background-color: transparent;
  height: 100%;
  pointer-events: none;
  position: absolute;
  width: 100%;
`

const YoutubePlaylistContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`

const YoutubeContainer = styled.div`
  background-color: rgba(0, 0, 0, 1);
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  transition: all 0.2s ease;
  width: 100%;

  &.youtube-container-showing {
    background-color: rgba(0, 0, 0, 0);
  }

  iframe {
    height: 100% !important;
    padding-bottom: 0 !important;
    position: absolute !important;
    width: 100% !important;
  }

  @media (min-width: 900px) {
    width: 65%;
  }
`

const YoutubeSidebar = styled.div`
  align-items: stretch;
  background-color: ${props => props.theme.color.n800};
  display: flex;
  flex-direction: column;
  max-height: 300px;
  width: 100%;

  h2 {
    color: #fff;
    padding: ${props => `${props.theme.size.l} ${props.theme.size.s} ${props.theme.size.s}`};
    width: 100%;
  }

  ol {
    flex-grow: 2;
    list-style: none;
    overflow-y: auto;
    padding: 0;

    li {
      margin: 0;

      button {
        display: flex;
        flex-direction: row;
        padding: ${props => props.theme.size.s};
        text-align: left;
        width: 100%;

        .video-index {
          color: ${props => props.theme.color.n100};
          flex-shrink: 0;
          margin-right: ${props => props.theme.size.xs};
        }

        .video-details {
          flex-grow: 2;
        }

        .video-title {
          color: ${props => props.theme.color.n60};
          flex-grow: 2;
          font-weight: bold;
        }

        .video-info {
          color: ${props => props.theme.color.n100};
          font-size: 0.9em;
          margin-top: ${props => props.theme.size.xxs};
        }

        &.current,
        :hover,
        :active {
          background-color: ${props => props.theme.color.n700};

          .video-title {
            color: ${props => props.theme.color.n20};
          }

          .video-index,
          .video-info {
            color: ${props => props.theme.color.n60};
          }
        }
      }

      :not(:last-child) {
        /* border-bottom: ${props => `1px solid ${props.theme.color.n500}`}; */
      }
    }
  }

  @media (min-width: 900px) {
    max-height: none;
    width: 35%;
  }
`

export default Youtube
