import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

const Disclaimers = ({ children, ...props }) => {
  return <DisclaimersContainer {...props}>{children}</DisclaimersContainer>
}

Disclaimers.propTypes = {
  children: PropTypes.node.isRequired,
}

const DisclaimersContainer = styled.div`
  p {
    color: ${props => props.theme.color.n300};
    font-size: 0.7em;
    line-height: 1.45;

    a {
      color: #377539;
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }
`

export default Disclaimers
