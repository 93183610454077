import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'
import Spacer from 'components/new/spacer'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Link } from 'gatsby'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'

const getTagText = type => {
  switch (type) {
    case 'cash-purchase-discount':
      return 'Cash Purchase Discount'
    case 'discount':
      return 'Discount'
    case 'financing-discount':
      return 'Financing Discount'
    case 'financing':
      return 'Financing'
    case 'see-more':
      return 'See More'
    default:
      return 'Offer'
  }
}

const PromotionsGrid = props => {
  const {
    className,
    promotions,
    showSeeMoreCard,
    seeMoreCardLink,
    seeMoreCardLinkText,
    seeMoreCardTitle,
    seeMoreCardSubtitle,
  } = props
  return (
    <PromotionsGridContainer className={className} {...props}>
      {promotions.map(promo => (
        <Card {...promo} key={promo.id} />
      ))}
      {showSeeMoreCard ? (
        <Card
          type='see-more'
          title={seeMoreCardTitle}
          subtitle={seeMoreCardSubtitle}
          link={seeMoreCardLink}
          linkText={seeMoreCardLinkText}
        />
      ) : null}
    </PromotionsGridContainer>
  )
}

PromotionsGrid.propTypes = {
  promotions: PropTypes.arrayOf(
    PropTypes.shape({
      disclaimerNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      id: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string.isRequired,
      type: PropTypes.oneOf([
        'cash-purchase-discount',
        'discount',
        'financing-discount',
        'financing',
      ]),
    })
  ),
  showSeeMoreCard: PropTypes.bool,
  seeMoreCardLink: PropTypes.string,
  seeMoreCardTitle: PropTypes.string,
  seeMoreCardSubtitle: PropTypes.string,
}

PromotionsGrid.defaultProps = {
  className: '',
  showSeeMoreCard: false,
  seeMoreCardLink: '/promotions/',
  seeMoreCardLinkText: 'View all promotions',
  seeMoreCardTitle: 'See more ways to save',
  seeMoreCardSubtitle: 'Check out our latest promotions, offers, and discounts.',
}

const PromotionsGridContainer = styled.div`
  ${clearfix}
  display: flex;
  flex-wrap: wrap;

  .column {
    background-color: ${props => props.theme.color.n20};
    padding: ${props => props.theme.size.m};
    margin-bottom: 16px;

    a {
      color: ${props => props.theme.color.g400};
      font-size: 16px;
      text-decoration: none;

      :hover,
      :focus {
        text-decoration: underline;
      }
    }

    @media ${props => props.theme.mediaQuery.small} {
      width: 100%;
    }

    @media ${props => props.theme.mediaQuery.smallUp} {
      ${column('1/2', 16)}
      display: flex;
      flex-direction: column;

      .spacer {
        flex-grow: 2;
      }
    }

    @media ${props => props.theme.mediaQuery.mediumUp} {
      ${column('1/3', 16)}
    }
  }

  .tag {
    color: ${props => props.theme.color.g400};
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 16px;
  }
`

const Card = props => {
  const { disclaimerNumber, link, linkText = 'Learn more', subtitle, tag, title, type } = props
  return (
    <div className='column'>
      <p className='tag'>{tag || getTagText(type)}</p>
      <H3>{title}</H3>
      {subtitle ? (
        <P>
          {subtitle}
          {disclaimerNumber ? <sup>{disclaimerNumber}</sup> : null}
        </P>
      ) : null}
      {link ? (
        <>
          <Spacer size='xs' />
          <Link to={link}>{linkText}</Link>
        </>
      ) : null}
    </div>
  )
}

export default PromotionsGrid
