const siteMetadata = require('../config/site-metadata')
const isObject = require('./is-object')
const isDefined = require('./is-defined')

/**
 *
 * @param {{large: [string], regular: string}} [image] MachineFinderEquipmentImage field
 * @param {object} [options] Options
 * @param {boolean} [options.relativeFallback=true] Get the fallback image's relative path
 * @returns {string} Preferred
 */
const getUsedEquipmentImageSrc = (image, options = {}) => {
  const { relativeFallback = true } = {
    ...options,
  }
  if (isObject(image)) {
    if (isDefined(image.large)) {
      return image.large
    }
    if (isDefined(image.regular)) {
      return image.regular
    }
  }
  return `${relativeFallback ? '' : siteMetadata.siteUrl}/no-images-available.jpg`
}

module.exports = getUsedEquipmentImageSrc
