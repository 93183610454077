/**
 * Format a price
 * @param {number} price Price to format
 * @param {object} [options] Options
 * @param {boolean} [options.round=false] Round up and don't show decimals
 * @returns {string}
 */
const formatPrice = (price, options = {}) => {
  const { round = false } = options
  if (round) {
    price = Math.ceil(price)
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: round ? 0 : 2,
    maximumFractionDigits: round ? 0 : 2,
  })
}

module.exports = formatPrice
