import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import { Helmet } from 'react-helmet'
import deereData from 'data/deere-data'
import Layout from 'components/layout'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import { graphql, Link } from 'gatsby'
import SeriesTable from 'components/category/series-table'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import formatPrice from 'utils/format-price'
import getSeriesSpecsTables from 'utils/get-series-specs-tables'
import { useEffectOnce } from 'hooks/use-effect-once'
import { Carousel } from 'components/new/carousel'
import Button from 'components/button/button'
import YoutubePlaylist from 'components/youtube-playlist'
import PromotionsGrid from 'components/new/promotions-grid'
import Disclaimers from 'components/new/disclaimers'
import { GatsbyImage } from 'gatsby-plugin-image'
import AspectRatioContainer from 'components/aspect-ratio-container'

const formatDisclaimers = disclaimers => {
  return disclaimers.map((disclaimer, index) => (
    <p key={index}>
      ({index + 1}) {disclaimer}
    </p>
  ))
}

const ProductSeries = props => {
  const {
    data: { allProducts, breadcrumbs, heroImage, meta, offers, seriesData, tables, titles },
  } = getInitialProps(props)

  const [activeOffers, setActiveOffers] = useState()
  const [disclaimers, setDisclaimers] = useState()

  useEffectOnce(() => {
    const disclaimers = []

    const activeOffers = offers
      .filter(offer => {
        const currentTimestamp = Date.now()
        const startDateTimestamp = new Date(offer.startDate).getTime()
        const endDateTimestamp = new Date(offer.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day

        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
      .map(offer => {
        const disclaimerMatchIndex = disclaimers.findIndex(val => val === offer.disclaimer)

        if (disclaimerMatchIndex === -1) {
          disclaimers.push(offer.disclaimer)
        }

        return {
          ...offer,
          disclaimerNumber:
            disclaimerMatchIndex === -1 ? disclaimers.length : disclaimerMatchIndex + 1,
        }
      })

    setActiveOffers(Array.isArray(activeOffers) && activeOffers.length > 0 ? activeOffers : null)
    setDisclaimers(disclaimers.length > 0 ? disclaimers : null)
  })

  const hasVideoSection = seriesData && seriesData.videoId
  const hasOffers = Array.isArray(activeOffers) && activeOffers.length > 0
  const showSecondaryContentSection =
    tables.length > 0 || (seriesData && seriesData.description) || hasOffers
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <meta name='keywords' content={meta.keywords} />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            ...meta.itemList,
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        image={heroImage.asset.gatsbyImageData}
        title={titles.series}
        breadcrumbs={breadcrumbs}
        overlayOpacity={0.15}
      />

      <LightBackground>
        <Content kind='full'>
          <ProductCarousel
            breakPoints={[
              { width: 1, itemsToShow: 1 },
              { width: 600, itemsToShow: 2, itemsToScroll: 2 },
              { width: 900, itemsToShow: 3, itemsToScroll: 3 },
              { width: 1200, itemsToShow: 4, itemsToScroll: 4 },
            ]}
            showEmptySlots={true}
          >
            {allProducts.map(({ id, ...product }) => (
              <ProductCarouselCard {...product} key={id} />
            ))}
          </ProductCarousel>
        </Content>
      </LightBackground>

      {showSecondaryContentSection ? (
        <Content kind='full'>
          {tables.map((table, index) => (
            <React.Fragment key={table.name}>
              {index !== 0 ? <Spacer /> : null}
              <H2>{table.name}</H2>
              <Spacer size='s' />
              <SeriesTable items={table.items} />
            </React.Fragment>
          ))}

          {seriesData && seriesData.description ? (
            <>
              <Spacer />
              <div>{seriesData.description}</div>
            </>
          ) : null}

          {hasOffers ? (
            <>
              <Spacer />
              <H2>Current Promotions</H2>
              <Spacer size='s' />
              <Promotions offers={activeOffers} />
            </>
          ) : null}
          {hasOffers && hasVideoSection ? null : Array.isArray(disclaimers) ? (
            <>
              <Spacer />
              <Disclaimers>{formatDisclaimers(disclaimers)}</Disclaimers>
            </>
          ) : null}
        </Content>
      ) : null}
      {hasVideoSection ? (
        <YoutubePlaylist list={seriesData.videoList} videoId={seriesData.videoId} title='Videos' />
      ) : null}
      {hasOffers && hasVideoSection && Array.isArray(disclaimers) ? (
        <DisclaimersContent kind='full'>
          <Disclaimers>{formatDisclaimers(disclaimers)}</Disclaimers>
        </DisclaimersContent>
      ) : null}
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 350px;
`

const ProductCarousel = styled(Carousel)`
  .nav-arrow {
    padding: 12px !important;
  }

  .rec-item-wrapper {
    height: 100%;
  }
`

const ProductCarouselCard = props => {
  const {
    name,
    sanityData: { firstImage, highlights, options, showPrice },
    slug,
    tabIndex,
  } = props
  return (
    <StyledProductCarouselCard>
      <AspectRatioContainer className='card-image-container'>
        {firstImage ? (
          <GatsbyImage
            image={firstImage.asset.gatsbyImageData}
            alt={name}
            objectFit='contain'
            objectPosition='50% 50%'
            style={{ height: '100%' }}
          />
        ) : (
          <MissingImage />
        )}
        <HiddenLink to={slug} />
      </AspectRatioContainer>
      <div className='card-text'>
        <h3 className='card-title'>
          {name}
          <HiddenLink to={slug} />
        </h3>
        {Array.isArray(highlights) && highlights.length > 0 ? (
          <ul className='card-highlights'>
            {highlights.map(highlight => (
              <li key={highlight}>{highlight}</li>
            ))}
          </ul>
        ) : null}
        {showPrice ? (
          <ul className='card-options'>
            {options.map(option => (
              <li key={option.title}>
                <span className='card-option-title'>{option.title}</span>
                <span className='card-option-price'>
                  {formatPrice(option.price, {
                    round: true,
                  })}
                </span>
              </li>
            ))}
          </ul>
        ) : null}
        <Button
          as={CardButtonLink}
          to={slug}
          aria-label={`Learn more about the ${name}`}
          tabIndex={tabIndex}
        >
          Learn more
        </Button>
      </div>
    </StyledProductCarouselCard>
  )
}

const StyledProductCarouselCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  padding: 24px;
  position: relative;
  width: 100%;

  .card-image-container {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin-top: 24px;
  }

  .card-title {
    display: inline-block;
    font-size: 21px;
    font-weight: 700;
    margin: 0 auto;
    max-width: none;
    padding: 0;
    position: relative;
    text-align: center;
    text-transform: none;

    :hover,
    :focus {
      color: ${props => props.theme.color.g400};
      text-decoration: underline;
    }
  }

  .card-highlights {
    list-style: none;
    margin: 16px 0 8px;
    padding: 0;

    li {
      border-top: 1px solid ${props => props.theme.color.n30};
      color: ${props => props.theme.color.n100};
      font-size: 1rem;
      margin: 0;
      padding: 8px 0;
      text-align: center;

      :last-child {
        border-bottom: 1px solid ${props => props.theme.color.n30};
      }
    }
  }

  .card-options {
    flex-grow: 2;
    line-height: 1.35;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      padding: 0;
    }

    .card-option-title {
      font-weight: 600;
      margin-right: 8px;
    }
  }
`

const MissingImage = () => {
  return (
    <StyledMissingImage>
      <span>Photo coming soon</span>
    </StyledMissingImage>
  )
}

const StyledMissingImage = styled.div`
  background-color: ${props => props.theme.color.n20};
  height: 100%;
  position: relative;
  width: 100%;

  span {
    color: ${props => props.theme.color.n400};
    display: block;
    font-size: 14px;
    position: absolute;
    text-align: center;
    transform: translateY(-50%);
    top: 50%;
    width: 100%;
  }
`

const HiddenLink = ({ to }) => {
  return (
    <Link
      to={to}
      aria-hidden='true'
      tabIndex='-1'
      style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}
    />
  )
}

const CardButtonLink = styled(Link)`
  align-self: flex-end;
  margin: 16px auto 0;
  padding: 6px;
  text-decoration: none;
`

const Promotions = ({ offers }) => {
  const [expanded, setExpanded] = useState(false)
  const topOffers = offers.slice(0, 3)
  const otherOffers = offers.slice(3)
  return (
    <>
      <PromotionsGrid
        promotions={[...topOffers, ...(expanded ? otherOffers : [])]}
        showSeeMoreCard={offers.length < 3}
        id='offers-grid'
      />
      {offers.length > 3 ? (
        <Button
          onClick={() => setExpanded(!expanded)}
          color='green'
          ghost
          aria-expanded={expanded}
          aria-controls='offers-grid'
        >
          {expanded ? <>Show fewer offers</> : <>Show more offers</>}
        </Button>
      ) : null}
    </>
  )
}

const DisclaimersContent = styled(Content)`
  padding-bottom: ${props => props.theme.size.m};
  padding-top: ${props => props.theme.size.m};
`

const getInitialProps = ({
  data: {
    allDeereProduct: { nodes: allDeereProductNodes },
    allSanityPromotion: { nodes: allSanityPromotionNodes },
    sanityJohnDeereEquipmentSeries: {
      handle,
      heroImageDesktop: heroImage,
      parentCategory: {
        handle: subcategoryHandle,
        parentCategory: { handle: categoryHandle, title: categoryTitle },
        title: subcategoryTitle,
      },
      title,
      titleSingular,
    },
  },
}) => {
  const titles = {
    category: categoryTitle,
    subcategory: subcategoryTitle,
    series: title,
  }
  const seriesData = deereData.find(obj => obj.series === handle)
  const meta = {
    title: `${titles.series} | Hutson Inc`,
    keywords: [titles.category, titles.subcategory, titles.series].toString(),
  }
  if (seriesData && seriesData.metaDescription) {
    meta.description = seriesData.metaDescription
  } else {
    meta.description = `Browse and compare ${titles.series} at Hutson.`
  }
  meta.itemList = {
    numberOfItems: allDeereProductNodes.length,
    itemListElement: allDeereProductNodes.map((product, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'url': `https://www.hutsoninc.com${product.slug}`,
    })),
  }
  const seriesSpecsTables = getSeriesSpecsTables(
    allDeereProductNodes,
    `Compare ${titleSingular} models`
  )
  const tables = seriesSpecsTables.reduce((acc, table) => {
    const itemsToKeep = table.items.filter(item => item.tableData.length > 0)
    if (itemsToKeep.length > 0) {
      acc.push(table)
    }
    return acc
  }, [])
  const breadcrumbs = [
    {
      name: 'Home',
      schemaName: 'Hutson Inc',
      link: '/',
    },
    {
      name: titles.category,
      link: `/${categoryHandle}/`,
    },
    {
      name: titles.subcategory,
      link: `/${categoryHandle}/${subcategoryHandle}/`,
    },
    {
      name: titles.series,
      link: `/${categoryHandle}/${subcategoryHandle}/${handle}/`,
    },
  ]

  return {
    data: {
      allProducts: allDeereProductNodes,
      breadcrumbs,
      heroImage,
      meta,
      offers: allSanityPromotionNodes,
      seriesData,
      tables,
      titles,
    },
  }
}

export const pageQuery = graphql`
  query productSeries($series: String!) {
    sanityJohnDeereEquipmentSeries(handle: { eq: $series }) {
      handle
      heroImageDesktop {
        asset {
          gatsbyImageData(width: 1366)
        }
      }
      parentCategory {
        handle
        parentCategory {
          handle
          title
        }
        title
      }
      title
      titleSingular
    }
    allDeereProduct(
      filter: { sanityData: { series: { handle: { eq: $series } } } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        id
        name
        sanityData {
          firstImage {
            asset {
              gatsbyImageData(width: 540)
            }
          }
          highlights
          options {
            price
            title
          }
          showPrice
        }
        slug
        seriesTableData {
          property
          info
        }
        seriesTableName
      }
    }
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $series }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allSanityPromotion(
      filter: {
        websiteEnabled: { eq: true }
        eligibleEquipment: { elemMatch: { series: { handle: { eq: $series } } } }
        type: { in: ["financing", "discount", "financing-discount", "cash-purchase-discount"] }
      }
      sort: { order: ASC, fields: [details___term, details___rate, details___discount] }
    ) {
      nodes {
        disclaimer
        details {
          term
        }
        endDate
        id
        subtitle
        startDate
        title
        type
      }
    }
  }
`

export default ProductSeries
