import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const AspectRatioContainer = ({ children, ratio, ...props }) => {
  return (
    <Container ratio={ratio} {...props}>
      <Inner>{children}</Inner>
    </Container>
  )
}

AspectRatioContainer.propTypes = {
  children: PropTypes.node.isRequired,
  ratio: PropTypes.number.isRequired,
}

AspectRatioContainer.defaultProps = {
  ratio: 56.25,
}

const Container = styled.div`
  display: block;
  height: 0px;
  overflow: hidden;
  padding-bottom: ${props => props.ratio}%;
  position: relative;
  width: auto;
`

const Inner = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

export default AspectRatioContainer
