import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Carousel, { consts } from 'react-elastic-carousel'
import { Bullet, BulletContainer } from 'components/new/bullet'
import NavArrow from 'components/new/nav-arrow'
import { PausePlayButton, PausePlayButtonContainer } from 'components/new/pause-play-button'

const ProductCarousel = ({ children, enableAutoPlay, enablePausePlay, kind, ...props }) => {
  const [isPlaying, setIsPlaying] = useState(enableAutoPlay)

  const handlePausePlay = () => {
    setIsPlaying(!isPlaying)
  }

  const renderPagination = ({ pages, activePage, onClick }) => {
    return (
      <PaginationContainer>
        <BulletContainer>
          {pages.map(page => {
            return (
              <Bullet
                active={activePage === page}
                index={page}
                onClick={() => onClick(page)}
                key={`Bullet${page}`}
                kind={kind}
              />
            )
          })}
        </BulletContainer>
        {enablePausePlay ? (
          <PausePlayButtonContainer>
            <PausePlayButton onClick={handlePausePlay} isPlaying={isPlaying} />
          </PausePlayButtonContainer>
        ) : null}
      </PaginationContainer>
    )
  }

  const renderArrow = ({ type, onClick }) => {
    const Arrow = type === consts.PREV ? LeftNav : RightNav
    return (
      <Arrow
        className={`nav-arrow ${type === consts.PREV ? 'left-nav-arrow' : 'right-nav-arrow'}`}
        direction={type === consts.PREV ? 'left' : 'right'}
        onClick={onClick}
        kind={kind}
      />
    )
  }
  return (
    <StyledCarousel
      renderPagination={renderPagination}
      renderArrow={renderArrow}
      enableAutoPlay={isPlaying}
      {...props}
    >
      {children}
    </StyledCarousel>
  )
}

ProductCarousel.propTypes = {
  children: PropTypes.node.isRequired,
  enableAutoPlay: PropTypes.bool.isRequired,
  enablePausePlay: PropTypes.bool.isRequired,
}

ProductCarousel.defaultProps = {
  autoPlaySpeed: 5000,
  breakPoints: [
    { width: 1, itemsToShow: 1 },
    { width: 250, itemsToShow: 2, itemsToScroll: 2 },
    { width: 480, itemsToShow: 3, itemsToScroll: 3 },
    { width: 600, itemsToShow: 4, itemsToScroll: 4 },
    { width: 800, itemsToShow: 5, itemsToScroll: 5 },
    { width: 900, itemsToShow: 6, itemsToScroll: 6 },
    { width: 1100, itemsToShow: 8, itemsToScroll: 8 },
  ],
  enableAutoPlay: false,
  enablePausePlay: false,
}

const StyledCarousel = styled(Carousel)`
  .rec-carousel {
    position: relative;
  }

  .rec-slider-container {
    margin: 0;
  }
`

const StyledNavArrow = styled(NavArrow)`
  padding: 16px;

  @media (max-width: 799px) {
    display: none;
  }
`

const LeftNav = styled(StyledNavArrow)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-100%, -50%);
`

const RightNav = styled(StyledNavArrow)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%, -50%);
`

const PaginationContainer = styled.div`
  margin: 28px auto 0;
  text-align: center;
`

export default ProductCarousel
