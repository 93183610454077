import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import UnstyledButton from 'components/button/unstyled-button'

const Bullet = ({ active, index, kind, ...props }) => {
  return (
    <StyledBullet
      data-active={active}
      data-theme={kind}
      aria-pressed={active}
      aria-label={`Go to slide ${index + 1}`}
      {...props}
    />
  )
}

Bullet.propTypes = {
  active: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  kind: PropTypes.oneOf(['dark', 'light']).isRequired,
}

Bullet.defaultProps = {
  active: false,
  kind: 'light',
}

const StyledBullet = styled(UnstyledButton)`
  display: inline-block;
  padding: 8px;

  :before {
    background-color: ${props => {
      switch (props['data-theme']) {
        case 'dark':
          if (props['data-active']) {
            return props.theme.color.y400
          }
          return 'rgba(255, 255, 255, 0.5)'
        case 'light':
        default:
          if (props['data-active']) {
            return props.theme.color.g400
          }
          return props.theme.color.n100
      }
    }};
    border-radius: 50%;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
  }

  :hover,
  :focus {
    :before {
      background-color: ${props => {
        switch (props['data-theme']) {
          case 'dark':
            if (props['data-active']) {
              return props.theme.color.y400
            }
            return 'rgba(255, 255, 255, 0.75)'
          case 'light':
          default:
            if (props['data-active']) {
              return props.theme.color.g400
            }
            return props.theme.color.n600
        }
      }};
    }
  }

  @media (max-width: 799px) {
    :before {
      height: 13px;
      width: 13px;
    }
  }
`

export default Bullet
