import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import UnstyledButton from 'components/button/unstyled-button'
import { IoMdPause } from '@react-icons/all-files/io/IoMdPause'
import { IoMdPlay } from '@react-icons/all-files/io/IoMdPlay'

const PausePlayButton = ({ isPlaying, kind, ...props }) => {
  return (
    <StyledPausePlayButton data-theme={kind} aria-label='Play or pause slider' {...props}>
      {isPlaying ? (
        <IoMdPause aria-hidden='true' focusable='false' role='presentation' />
      ) : (
        <IoMdPlay aria-hidden='true' focusable='false' role='presentation' />
      )}
    </StyledPausePlayButton>
  )
}

PausePlayButton.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  kind: PropTypes.oneOf(['dark', 'light']).isRequired,
}

PausePlayButton.defaultProps = {
  kind: 'light',
}

const StyledPausePlayButton = styled(UnstyledButton)`
  padding: 8px;

  svg {
    color: ${props => {
      switch (props['data-theme']) {
        case 'dark':
          return 'rgba(255, 255, 255, 0.5)'
        case 'light':
        default:
          return props.theme.color.n100
      }
    }};
    font-size: 12px;
  }

  :hover,
  :focus {
    svg {
      color: ${props => {
        switch (props['data-theme']) {
          case 'dark':
            return 'rgba(255, 255, 255, 0.75)'
          case 'light':
          default:
            return props.theme.color.n600
        }
      }};
    }
  }

  @media (max-width: 799px) {
    svg {
      font-size: 13px;
    }
  }
`

export default PausePlayButton
