import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import P from 'components/new/typography/p'

const ProductCard = ({ image, imageAlt, title, link, ...props }) => {
  return (
    <StyledProductCard {...props}>
      <Link to={link}>
        <GatsbyImage image={image} alt={imageAlt} objectPosition='50% 50%' objectFit='contain' />
        <TextContainer>
          <Title>{title}</Title>
        </TextContainer>
      </Link>
    </StyledProductCard>
  )
}

ProductCard.propTypes = {
  imageAlt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

ProductCard.defaultProps = {
  imageAlt: '',
}

const StyledProductCard = styled.div`
  width: 100%;

  > a {
    background-color: #fff;
    color: #000;
    display: block;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

const TextContainer = styled.div`
  margin-top: 8px;

  * {
    text-align: center;
  }
`

const Title = styled(P)`
  font-weight: bold;
  line-height: 1.35;
`

export default ProductCard
