/**
 * Check if a given value is an object
 * @param {*} val - Value to test
 * @returns {boolean}
 */
const isObject = val => {
  if (Object.prototype.toString.call(val) !== '[object Object]') {
    return false
  }

  const prototype = Object.getPrototypeOf(val)
  return prototype === null || prototype === Object.prototype
}

module.exports = isObject
